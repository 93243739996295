import React from 'react'
import DefaultFooter from './DefualtFooter'

function Instruction() {
  return (
    <div>
      <div className='container shadow rounded bg-white my-5'>
        <h1 className='text-dark py-2'>Instructions</h1>
        <div className='pb-3 text-start ps-3'>
        <p>* Download career form from Downloads.</p>
        <p>* Send it by Email or drop it physicaly on Campus.</p>
        </div>
      </div>
      <DefaultFooter/>
    </div>
  )
}

export default Instruction