import React from 'react';
import DefaultFooter from './DefualtFooter';

function Founder() {
  return (
    <div>
      <div className="container my-5 py-5">
        <div className="team-single contact__wrapper shadow-lg p-5">
          <div className="row">
            <div className="col-lg-4 col-md-5 xs-margin-30px-bottom">
              <div className="team-single-img">
                <img src="/image/Founder.jpeg" alt="Founder" className="img-fluid"style={{width:'18rem'}} />
              </div>
              <div className="bg-name padding-30px-all md-padding-25px-all sm-padding-20px-all text-center">
                <h4 className="margin-10px-bottom font-size24 md-font-size22 sm-font-size20 font-weight-600 text-color">Haji Muhammad Tufail Saroya (Late)</h4>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 ps-md-5">
              <div className="team-single-text padding-50px-left sm-no-padding-left w-100">
                <h4 className="font-size48 sm-font-size32 xs-font-size30 text-colorb">Founder's Message</h4>
                <p className="no-margin-bottom text-colorb">
                  It is my belief that education is
                  a way for individuals to gain upward
                  mobility, to improve their social
                  status and to enhance their personal
                  growth.
                </p>
                <p className="no-margin-bottom text-colorb">
                  As founder of the CSS, it would
                  be my utmost desire that the CSS be
                  remained committed to provide
                  quality education to its students. To
                  honour our commitment to this goal,
                  CSS attempts to maintain a faculty
                  dedicated to effective teaching;
                  and committed to the intellectual
                  and social development of each
                  student. CSS actively supports the
                  professional advancement and
                  personal enrichment of its faculty
                  and its hardworking students.
                </p>
                <p className="no-margin-bottom text-colorb">May God be with you in your endeavors!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}

export default Founder;
