import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { gallery } from '../apiData/Gallery';
import { Link } from 'react-router-dom';

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

function Event({ data }) {
  const sliderRef = useRef();
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768; // Assuming 768 is your breakpoint for mobile

  useEffect(() => {
    if (!isMobile && sliderRef.current) { // Ensure sliderRef is not null and not in mobile view
      const interval = setInterval(() => {
        sliderRef.current.slickNext();
      }, 3000); // Change slide every 3 seconds

      return () => clearInterval(interval);
    }
  }, [isMobile]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // Define the card component within the slider component
  const Card = ({ title, image }) => (
    <div className="card m-2">
      <img style={{ height: '15rem' }} src={image} alt={title} />
    </div>
  );

  return (
    <div>
      {!isMobile && ( // Render the slider only if not in mobile view
        <Link to='/gallery'>
        <div className='container bg-white mt-5 p-5 rounded shadow'>
          <Slider ref={sliderRef} {...settings}>
            {gallery.map((item, index) => (
              <div key={index}>
                <Card image={item.image} />
              </div>
            ))}
          </Slider>
        </div>
        </Link>
      )}
    </div>
  );
}

export default Event;
