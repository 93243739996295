import React, { useEffect, useState } from 'react';
import { calendar } from '../apiData/Calendar';
import "../style/Calendar.css";
import DefaultFooter from './DefualtFooter';

function Calendar() {
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://api.rapidmis.com/Event/ViewAll')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(result => {
        setEventList(result.Events);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="event-schedule-area-two bg-color pad100">
        <div className="container my-4 rounded bg-white">
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <div className="title-text">
                  <h2 className=''>Event Schedule</h2>
                </div>
              </div>
            </div>
          </div> */}
          {/* row end*/}
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade active show" id="home" role="tabpanel">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr >
                          <th className='bg-danger'scope="col">Date</th>
                          <th className='bg-danger'scope="col">Event Name</th>
                          <th className='bg-danger'scope="col">Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventList.map((x, index) => (
                          <tr className="inner-box" key={index}>
                            <td>
                            <div className="event-wrap">
                                <p>{x.Date}</p>
                              </div>
                            </td>
                            <td>
                              <div className="event-wrap">
                                <p>{x.Title}</p>
                              </div>
                            </td>
                            <td>
                              <div className="event-wrap">
                                <p>{x.Description}</p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /col end*/}
          </div>
          {/* /row end*/}
        </div>
      </div>
      <DefaultFooter/>
    </div>
  );
}

export default Calendar;
