import React, { useState, useEffect } from 'react';
import "../style/AlertNavbar.css";
import { Link } from 'react-router-dom';

function AlertNavbar() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://api.rapidmis.com/Event/ViewAll');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setEventList(result.Events.slice(0, 3));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex =>
        prevIndex === eventList.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [eventList]);

  return (
    <nav className="navbar bg-AlertNav">
      <div className="container px-5">
        <Link className="navbar-brand" to="/">
          <img src="/image/logo2.png" alt="Logo" width={100} height={100} />
        </Link>
        <div className="carousel-container" style={{ width: "50rem" }}>
          <div className="carousel slide" id="alertCarouselExampleAutoplaying" data-bs-ride="carousel">
            <div className="carousel-inner">
              {eventList.length > 0 ? (
                eventList.map((x, index) => (
                  <div className={`carousel-item ${index === activeIndex ? 'active' : ''}`} key={index}>
                    <div className="carousel-content">
                      <h1 className='alert-title'>{x.Title}</h1>
                      {x.Description ? (
                        <p className='alert-detail'>{x.Description.slice(0, 40) + ".."}</p>
                      ) : (
                        <p className='alert-detail'>No description available</p>
                      )}
                      <Link to={`/alert`}>
                        <button className="alertButton">
                          Click For detail..
                        </button>
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div className="carousel-item active">
                  <div className="carousel-content">
                    <h1 className='alert-title'>No Alerts Available</h1>
                    <p className='alert-detail'>Please check back later.</p>
                  </div>
                </div>
              )}
            </div>
            {/* Uncomment these if you need carousel controls */}
            {/* 
            <button className="carousel-control-prev" type="button" data-bs-target="#alertCarouselExampleAutoplaying" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#alertCarouselExampleAutoplaying" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="visually-hidden">Next</span>
            </button>
            */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default AlertNavbar;
