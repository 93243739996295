import logo from './logo.svg';
import './App.css';
import HelpDeskNavbar from './components/HelpDeskNavbar';
import AlertNavbar from './components/AlertNavbar';
import MenuNavbar from './components/MenuNavbar';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Contact from './components/Contact';
import Glance from './components/Glance';
import Founder from './components/Founder';
import Chairman from './components/Chairman';
import Gallery from './components/Gallery';
import Faculty from './components/Faculty';
import Principal from './components/Principal';
import Achievment from './components/Achievment';
import Calendar from './components/Calendar';
import Career from './components/Career';
import Instruction from './components/Instruction';
import Alert from './components/Alert';

function App() {

  const footerStyle = {
    position: 'fixed',
    bottom: '0',
    right: '0',
    zIndex: '5',
    backgroundColor: '#2d2a2ac0', // Slightly transparent grey
    color: '#ffffff', // White text
    padding: '2px 20px',
    borderRadius: '10px 0 0 0',
    fontSize: '12px', // Minimized font size
  };

  const footerLinkStyle = {
    textDecoration: 'none',
    color: '#ffffff', // Ensure text color matches footer text color
  };
  
  return (
    <div className="App">
      <HelpDeskNavbar/>
      <AlertNavbar/>
      <MenuNavbar/>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/home' element={<Home/>}></Route>
        <Route path='/achievment' element={<Achievment/>}></Route>
        <Route path='/gallery' element={<Gallery/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/glance' element={<Glance/>}></Route>
        <Route path='/chairman' element={<Chairman/>}></Route>
        <Route path='/principal' element={<Principal/>}></Route>
        <Route path='/founder' element={<Founder/>}></Route>
        <Route path='/faculty' element={<Faculty/>}></Route>
        <Route path='/calendar' element={<Calendar/>}></Route>
        <Route path='/career' element={<Career/>}></Route>
        <Route path='/instruction' element={<Instruction/>}></Route>
        <Route path='/alert' element={<Alert/>}></Route>

      </Routes>

      <div className="fixSocial " >
        <a href="https://www.facebook.com/csskamoki"><img className="img-responsive " src="./image/facebook.png"/></a>
        <a href="https://twitter.com/"><img className="img-responsive" src="./image/twitter.png"/></a>
        <a href="https://www.youtube.com/playlist?list=PLf-xqIGQ9Gv7UkwWObxS7CXdO-s46Qk66"><img className="img-responsive " src="./image/youtube.png"/></a>
        <a href="https://www.tiktok.com/@comprehensive.hig"><img className="img-responsive " src="./image/tiktok.png"/></a>
        <a href="https://play.google.com/store/apps/details?id=com.js.chs&pcampaignid=web_share"><img className="img-responsive " src="./image/playstore.png"/></a>
      </div>

      <div style={footerStyle}>
        <a href="https://rapidmis.com/" style={footerLinkStyle} target="_blank" rel="noopener noreferrer">
          Powered By RapidMIS
        </a>
      </div>

    </div>
  );
}

export default App;
