import React from 'react';
import "../style/Chairman.css";
import DefaultFooter from './DefualtFooter';

function Principal() {
  return (
    <div>
      <div className="container my-5">
        <div className="team-single contact__wrapper shadow-lg p-5">
          <div className="row">
            <div className="col-lg-4 col-md-5 xs-margin-30px-bottom">
              <div className="team-single-img">
                <img src="/image/fAvatar.webp" alt="Principal" className="img-fluid" style={{width:'18rem'}} />
              </div>
              <div className="bg-name padding-30px-all md-padding-25px-all sm-padding-20px-all text-center">
                <h4 className="margin-10px-bottom font-size24 md-font-size22 sm-font-size20 font-weight-600 text-color">Mrs. Uzma Irfan</h4>
                <h5 className="margin-10px-bottom font-size24 md-font-size22 sm-font-size20 font-weight-600 text-color">M.A. M. Ed.</h5>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 ps-md-5">
              <div className="team-single-text padding-50px-left sm-no-padding-left w-100">
                <h4 className="font-size48 sm-font-size32 xs-font-size30 text-colorb">Principal's Message</h4>
                <p className="no-margin-bottom text-colorb">
                  Dear parents & students,
                  Human nature has rightly been
                  described as three folds: physical,
                  intellectual and moral. Good
                  education aims at building up the
                  personality of a student. Classroom
                  education is being supplemented by
                  physical education now-a-days. But
                  it is really a very sad fact that a very
                  little or no moral education is
                  imparted to the students. It is only
                  through Islamic education that we
                  can build up the character of
                  students. So what we emphatically
                  aim at is to adopt an innovative
                  curriculum based on the sacrosanct
                  dogmas of Islamic values and
                  ideology of Pakistan, conducive to
                  the prevalent social environment.
                </p>
                <p className="no-margin-bottom text-colorb">
                  Keeping this in view, we have
                  a fully equipped science room to
                  make a student practically aware of
                  the true knowledge of science.
                </p>
                <p className="no-margin-bottom text-colorb">Wishing you the best in life,</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}

export default Principal;
