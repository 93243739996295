import React, { useState } from 'react';
import "../style/MenuNavbar.css";
import { Link } from 'react-router-dom';

function MenuNavbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleMouseEnter = (dropdownName) => {
    setOpenDropdown(dropdownName);
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeNavbar = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg bg-MenuNav">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" onClick={toggleDropdown}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isDropdownOpen ? 'show' : ''}`}>
          
          <ul className="navbar-nav mx-auto menu">
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/" onClick={closeNavbar}>HOME</Link>
            </li>
            
            <li className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('about')}
                onMouseLeave={handleMouseLeave}>
              <a className="nav-link active dropdown-toggle s" role="button" aria-expanded={openDropdown === 'about'} href="#">About</a>
              <ul className={`dropdown-menu ${openDropdown === 'about' ? 'show' : ''}`}>
                <li><Link className="dropdown-item" to="/glance" onClick={handleMouseLeave}>CHS at a glance</Link></li>
                <li><Link className="dropdown-item" to="/founder" onClick={handleMouseLeave}>Founder message</Link></li>
                <li><Link className="dropdown-item" to="/chairman" onClick={handleMouseLeave}>Chairman message</Link></li>
                <li><Link className="dropdown-item" to="/principal" onClick={handleMouseLeave}>Principal message</Link></li>
              </ul>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/calendar" onClick={closeNavbar}>CALENDAR</Link>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/achievment" onClick={closeNavbar}>ACHIEVEMENTS</Link>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/gallery" onClick={closeNavbar}>GALLERY</Link>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/faculty" onClick={closeNavbar}>FACULTY</Link>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/career" onClick={closeNavbar}>CAREER</Link>
            </li>
            
            <li className="nav-item dropdown"
                onMouseEnter={() => handleMouseEnter('downloads')}
                onMouseLeave={handleMouseLeave}>
              <a className="nav-link active dropdown-toggle s" role="button" aria-expanded={openDropdown === 'downloads'} href="#">DOWNLOADS</a>
                <ul className={`dropdown-menu ${openDropdown === 'downloads' ? 'show' : ''}`}>
                <li><a className="dropdown-item" href={process.env.PUBLIC_URL + "/pdf/Prospectus.pdf"} download onClick={handleMouseLeave}>Prospectus</a></li>
                <li><a className="dropdown-item" href={process.env.PUBLIC_URL + "/pdf/AdmissionForm.pdf"} download onClick={handleMouseLeave}>Admission Form</a></li>
                <li><a className="dropdown-item" href={process.env.PUBLIC_URL + "/pdf/CareerForm.pdf"} download onClick={handleMouseLeave}>Career Form</a></li>
                </ul>

            </li>

            <li className="nav-item">
              <a className="nav-link active s" aria-current="page" href="https://demo.rapidmis.com/" onClick={closeNavbar}>LOGIN</a>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link active s" aria-current="page" to="/contact" onClick={closeNavbar}>CONTACT US</Link>
            </li>
          </ul>
         
        </div>
      </div>
    </nav>
  );
}

export default MenuNavbar;
