import React from 'react';
import "../style/Chairman.css";
import DefaultFooter from './DefualtFooter';

function Chairman() {
  return (
    <div className='chairmanBody'>
      <div className="container my-5 py-5">
        <div className="team-single contact__wrapper shadow-lg p-5">
          <div className="row">
            <div className="col-lg-4 col-md-5 xs-margin-30px-bottom">
              <div className="team-single-img">
                <img src="/image/CM.JPG" alt="" />
              </div>
              <div className="bg-name padding-30px-all md-padding-25px-all sm-padding-20px-all text-center">
                <h4 className="margin-10px-bottom font-size24 md-font-size22 sm-font-size20 font-weight-600 text-color">Mahmood Tufail Saroya</h4>
                <h5 className="margin-10px-bottom font-size24 md-font-size22 sm-font-size20 font-weight-600 text-color">M.A. (Eng), M.Ed.</h5>
              </div>
            </div>
            <div className="col-lg-8 col-md-7 ps-md-5">
              <div className="team-single-text padding-50px-left sm-no-padding-left w-100">
                <h4 className="font-size48 sm-font-size32 xs-font-size30 text-colorb">Chairman's Message</h4>
                <p className="no-margin-bottom text-colorb">The world has become a
                  fiercely competitive arena. For
                  Pakistan, to enter into and win the
                  race for economic and political selfsufficiency, it is imperative that our
                  youth be developed into welleducated, perfectly groomed
                  administrators capable of
                  competing and winning in any
                  environment, all the while
                  maintaining their individual &
                  national character. We strongly
                  believe that we can play a major
                  role in producing such individuals.
                </p>
                <p className="no-margin-bottom text-colorb">We welcome you as a new
                  student at CSS and we believe that
                  at the completion of your study you
                  will be transformed into a thoroughly
                  competent professional, capable of
                  handling the most challenging of
                  careers. Most of all, we would like
                  you to become a responsible &
                  caring human being leading your
                  country to a glorious future. Let the
                  sky be the limit in your personal
                  growth & development. We strive to
                  achieve the career goals that you
                  have set for yourself.
                </p>
                <p className="no-margin-bottom text-colorb">Wishing you the best in life,</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DefaultFooter />
    </div>
  );
}

export default Chairman;
