import React from 'react'
import HomeSlider from './HomeSlider'
import DefualtFooter from './DefualtFooter'
import Chairman from './Chairman'
import Event from './Event'
import Facilties from './Facilties'


function Home() {
  return (
    <div className='bg-white'>
      <HomeSlider/>
      
      <Event/>
      <Facilties/>
      <Chairman/>
      
    </div>
  )
}

export default Home