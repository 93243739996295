import React from 'react'
import "../style/HelpDeskNavbar.css"
import { Link } from 'react-router-dom'

function HelpDeskNavbar() {
  return (
    <nav class="navbar navbar-expand-lg bg">
    <button className="navbar-toggler m-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
  <div class="container  px-5">
      
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#"><i class="fa-solid fa-phone"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">0321-6428474</a>
        </li>
        
      </ul>
      <ul class="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#"><i class="fa-solid fa-inbox"></i></a>
        </li>
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/contact">Help Desk</Link>
        </li>
        
      </ul>
    </div>
  </div>
</nav>
  )
}

export default HelpDeskNavbar