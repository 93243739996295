import React from 'react'
import DefaultFooter from './DefualtFooter'
import { gallery } from '../apiData/Gallery'
 

function Gallery() {
  return (
                  	  
<div className='galleryBody'>
  <div className="container bootstrap snippets bootdey my-5 bg-white rounded ps-5 pe-4 shadow">
    <div className="row">
      <div className="row">
      {gallery.map((x, index) => (
    <div className="col-lg-3 col-sm-4 col-xs-6 my-5" key={index}>
        <a title="Image 1" href="#"><img className="thumbnail img-responsive" src={x.image} style={{height:'15rem'}} /></a>
    </div>
))}

      </div>
    </div>
  </div>
  
  <DefaultFooter/>
</div>

  )
}

export default Gallery