import React from 'react';
import "../style/Footer.css";

function DefaultFooter() {
  return (
    <section id="footer">
      <div className="container">
        <div className="row text-center text-xs-center text-sm-left text-md-left">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <h5>CONTACT INFO</h5>
            <ul className="list-unstyled quick-links">
              <li><a href="javascript:void();"><i class="fa-solid fa-house"></i> GT. Road. Kamoke</a></li>
              <li><a href="javascript:void();"><i class="fa-solid fa-envelope"></i> chskamoke1@gmail.com</a></li>
              <li><a href="javascript:void();"><i class="fa-solid fa-phone"></i> 0321-6428474 / 0304-6428484</a></li>
             
              
            </ul>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            {/* Add content for the second column if needed */}
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
  <ul className="list-unstyled quick-links">
    <li>
      <div className="embed-responsive embed-responsive-4by3">
        <iframe
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Comprehensive%20High%20School%20kamoke+(Comprehensive%20High%20School)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          allowFullScreen
          title="Location Map"
          style={{ width: '100%', height: '230px' }}
        ></iframe>
      </div>
    </li>
  </ul>
</div>

        </div>
<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
					<ul class="list-unstyled list-inline social text-center">
						<li class="list-inline-item"><a href="https://www.facebook.com/csskamoki"><i class="fa fa-facebook"></i></a></li>
						<li class="list-inline-item"><a href="https://twitter.com/"><i class="fa-brands fa-twitter"></i></a></li>
						<li class="list-inline-item"><a href="https://www.youtube.com/playlist?list=PLf-xqIGQ9Gv7UkwWObxS7CXdO-s46Qk66"><i class="fa-brands fa-youtube"></i></a></li>
						<li class="list-inline-item"><a href="https://www.tiktok.com/@comprehensive.hig"><i class="fa-brands fa-tiktok"></i></a></li>
						<li class="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.js.chs&pcampaignid=web_share" target="_blank"><i class="fa-brands fa-google-play"></i></a></li>
					</ul>
				</div>
				
			</div>	
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
            <p className="h6">© All Rights Reserved.CHS</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DefaultFooter;
