export const gallery = [
    {
        id: 1,
        image: "./image/gallery/chs.jpg"
    },
    {
        id: 2,
        image: "./image/gallery/img1.jpg"
    },
    {
        id: 3,
        image: "./image/gallery/img2.jpg"
    },
    {
        id: 4,
        image: "./image/gallery/img3.jpg"
    },
    {
        id: 5,
        image: "./image/gallery/img4.jpg"
    },
    {
        id: 6,
        image: "./image/gallery/img5.jpg"
    },
    {
        id: 7,
        image: "./image/gallery/img6.jpg"
    },
    {
        id: 8,
        image: "./image/gallery/img7.jpg"
    },
    {
        id: 9,
        image: "./image/gallery/img8.jpg"
    },
    {
        id: 10,
        image: "./image/gallery/img9.jpg"
    },
    {
        id: 11,
        image: "./image/gallery/img10.jpg"
    },
    {
        id: 12,
        image: "./image/gallery/img11.jpg"
    },
    {
        id: 13,
        image: "./image/gallery/img12.jpg"
    },
    {
        id: 14,
        image: "./image/gallery/img13.JPG"
    },
    {
        id: 15,
        image: "./image/gallery/img14.JPG"
    },
    {
        id: 16,
        image: "./image/gallery/img15.JPG"
    }
    

    
]