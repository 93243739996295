import React from 'react'
import { career } from '../apiData/Career';
import "../style/Career.css";
import { Link } from 'react-router-dom';
import DefaultFooter from './DefualtFooter';

function Career() {
  return (
    <div>
        <section class="section bg-white">
    <div class="container">
        <div class="row md-m-25px-b m-45px-b justify-content-center text-center">
            <div class="col-lg-8">
                <h3 class="h1 m-15px-b">Job Positions</h3>
                
            </div>
        </div>
        
        <div class="row">
        {career.map((x, index) => (
            <div class="col-sm-6 col-lg-4 m-15px-tb">
                <div class="media box-shadow-only-hover hover-top border-all-1 border-color-gray p-15px">
                    <a class="overlay-link" href="#"></a>
                    {/* <div class="icon-50 theme-bg white-color border-radius-50 d-inline-block">
                        <i class="number">LD</i>
                    </div> */}
                    <div class="p-20px-l media-body">
                        <span class="theme2nd-bg white-color p-0px-tb p-10px-lr font-small border-radius-15">Full time</span>
                        <h6 class="m-5px-tb text-black">{x.title}</h6>
                        <p class="m-0px font-small text-danger"><u>{x.education}</u></p>
                        <p class="m-0px font-small text-danger">{x.experience}</p>
                    </div>
                </div>
            </div>
            ))}
         

            <div class="col-12 p-25px-t text-center">
                <Link class="m-btn m-btn-radius m-btn-theme" to="/instruction">Read More</Link>
            </div>
        </div>
    </div>
</section>
<DefaultFooter/>
    </div>
  )
}

export default Career