import React, { useEffect, useState } from 'react'
import DefaultFooter from './DefualtFooter'
import "../style/Alert.css";
import { alert } from '../apiData/Alert';

function Alert() {

  const [eventList, setEventList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://api.csskamoke.edu.pk/Event/ViewAll')
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        return response.json();
      })
      .then(result => {
        setEventList(result.Events.slice(0,3));
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);
  return (
    <div>
      <div className="container bg-white my-5 rounded">
        <div className="row">
        {eventList.map((x) => (  
          <div className="col-md-6">
            {/* Success */}
              <div className="g-brd-around g-brd-gray-light-v7 g-rounded-4 g-pa-15 g-pa-20--md g-mb-30 mt-2">
                <h3 className="d-flex align-self-center text-uppercase g-font-size-12 g-font-size-default--md g-color-black g-mb-20">{x.Title}</h3>
                <div className="noty_bar noty_type__success noty_theme__unify--v1 g-mb-25">
                  <div className="noty_body">
                    <div className="g-mr-20">
                      <div className="noty_body__icon">
                        <i className="hs-admin-check" />
                      </div>
                    </div>
                    <div>{x.Description}</div>
                  </div>
                  <div className="noty_body">
                    <div className="g-mr-20">
                      <div className="noty_body__icon">
                        <i className="hs-admin-check" />
                      </div>
                    </div>
                    <div>{x.Date}</div>
                  </div>
                </div>
              </div>
          </div>
        ))}
        </div>
  
      </div>

        <DefaultFooter/>
    </div>
  )
}

export default Alert