import React from 'react'
import { achievment } from '../apiData/Achievment'
import "../style/Achievment.css";

function Achievment() {
  return (
    <div className='container'>
      <div className="container-fluid bg-white my-4 rounded">
      
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <div className="title-text">
              <h2 className='bg-dark text-white py-3 px-2'>Gujranwala Board Results</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="main-box clearfix">
            <div className="table-responsive">
              <table className="table user-list">
                <thead className=''>
                  <tr className=''>
                    <th><span>Class</span></th>
                    <th><span>Name</span></th>
                    <th><span>Roll Number</span></th>
                    <th><span>Obtain Marks</span></th>
                    <th><span>Total Marks</span></th>
                    <th><span>Year</span></th>
                  </tr>
                </thead>
                <tbody>
                  {achievment.map((x, index) => (
                    <tr key={index} className='hover'>
                      
                    <td>
                        {x.class}
                      </td>
                      <td >
                      {x.gender === "Male" ? (
                        <img src="https://bootdey.com/img/Content/avatar/avatar6.png" className="img-fluid" alt="Male faculty" />
                        ) : (
                        <img src="https://bootdey.com/img/Content/avatar/avatar8.png" className="img-fluid" alt="Female faculty" />
                        )}
                        {x.name}
                      </td>
                      <td>
                        {x.rollNumber}
                      </td>
                      <td>
                        {x.obtainMarks}
                      </td>
                      <td>
                        {x.totalMarks}
                      </td>
                      <td>
                        {x.year}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default Achievment