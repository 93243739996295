export const achievment = [
    {
        id: 1,
        name: "Haleema Mujtaba",
        gender: "Female",
        rollNumber: "205694",
        obtainMarks: "479",
        totalMarks: "505",
        year: "2019",
        class: "Nine"
    },
    {
        id: 2,
        name: "Aima",
        gender: "Female",
        rollNumber: "207505",
        obtainMarks: "504",
        totalMarks: "505",
        year: "2021",
        class: "Nine"
    },
    {
        id: 3,
        name: "Shezal Laraib",
        gender: "Female",
        rollNumber: "195779",
        obtainMarks: "490",
        totalMarks: "550",
        year: "2022",
        class: "Nine"
    },
    {
        id: 4,
        name: "Haleema Mujtaba",
        gender: "Female",
        rollNumber: "491441",
        obtainMarks: "1044",
        totalMarks: "1100",
        year: "2020",
        class: "Ten"
    },
    {
        id: 5,
        name: "Aima Sabir",
        gender: "Female",
        rollNumber: "511082",
        obtainMarks: "1100",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 6,
        name: "Muntaha Afzaal",
        gender: "Female",
        rollNumber: "510751",
        obtainMarks: "1098",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 7,
        name: "Samavia Noor",
        gender: "Female",
        rollNumber: "511054",
        obtainMarks: "1096",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 8,
        name: "Ayesha Noor",
        gender: "Female",
        rollNumber: "511460",
        obtainMarks: "1096",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 9,
        name: "Zainab Mujtaba",
        gender: "Female",
        rollNumber: "511186",
        obtainMarks: "1094",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 10,
        name: "Amna",
        gender: "Female",
        rollNumber: "510133",
        obtainMarks: "1092",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 11,
        name: "Noor Un Nisa",
        gender: "Female",
        rollNumber: "510479",
        obtainMarks: "1090",
        totalMarks: "1100",
        year: "2021",
        class: "Ten"
    },
    {
        id: 12,
        name: "Amina",
        gender: "Female",
        rollNumber: "486684",
        obtainMarks: "1077",
        totalMarks: "1100",
        year: "2022",
        class: "Ten"
    },
    {
        id: 13,
        name: "Shezal Laraib",
        gender: "Female",
        rollNumber: "474575",
        obtainMarks: "1054",
        totalMarks: "1100",
        year: "2023",
        class: "Ten"
    }

    
]