import React from 'react';
import "../style/Facilties.css";

function Facilities() {
  return (
    <div className="gbb-row-wrapper my-5">
      <div className='container'>
        <div className='row justify-content-center align-items-center gx-1 gy-1'>

        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
            <div className="card faciltyHover p-3" style={{width: '18rem', border: '0', borderRadius: '0.3rem', backgroundColor: '#EEF5FF' }}>
              <div className="icon-container rounded-circle d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px', backgroundColor: '#007bff', margin: '0 auto' }}>
                <i className="fa-solid fa-house" style={{ fontSize: '2rem', color: 'white' }}></i>
              </div>
              <div className="card-body"  style={{height: '14rem',  overflow: 'hidden'}}>
                <h5 className="card-text">PROJECT BASED STUDY</h5>
                <p className="card-text text-black">Visionary and papered education is provided a metal base by education through hand made projects made by students.</p>

              </div>
            </div>

            
        </div>

        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
            <div className="card faciltyHover p-3" style={{width: '18rem', border: '0', borderRadius: '0.3rem', backgroundColor: '#EEF5FF' }}>
              <div className="icon-container rounded-circle d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px', backgroundColor: '#007bff', margin: '0 auto' }}>
                <i className="fa-solid fa-flask-vial" style={{ fontSize: '2rem', color: 'white' }}></i>
              </div>
              <div className="card-body"  style={{height: '14rem',  overflow: 'hidden'}}>
                <h5 className="card-text">SCIENCE LAB</h5>
                <p className="card-text text-black">A well equipped lab with modern instruments plays the key role in focused studies of students.</p>

              </div>
            </div>
        </div>

        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
            <div className="card faciltyHover p-3" style={{width: '18rem', border: '0', borderRadius: '0.3rem', backgroundColor: '#EEF5FF' }}>
              <div className="icon-container rounded-circle d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px', backgroundColor: '#007bff', margin: '0 auto' }}>
                <i className="fa-solid fa-book-open" style={{ fontSize: '2rem', color: 'white' }}></i>
              </div>
              <div className="card-body"  style={{height: '14rem',  overflow: 'hidden'}}>
                <h5 className="card-text">LIBRARY</h5>
                <p className="card-text text-black">A charming library embellished with thounsands of books provides students chance to satisfy their thirst for enhanced search work.</p>

              </div>
            </div>
        </div>

        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center'>
            <div className="card faciltyHover p-3" style={{width: '18rem', border: '0', borderRadius: '0.3rem', backgroundColor: '#EEF5FF' }}>
              <div className="icon-container rounded-circle d-flex justify-content-center align-items-center" style={{ width: '80px', height: '80px', backgroundColor: '#007bff', margin: '0 auto' }}>
                <i className="fa-solid fa-graduation-cap" style={{ fontSize: '2rem', color: 'white' }}></i>
              </div>
              <div className="card-body"  style={{height: '14rem',  overflow: 'hidden'}}>
                <h5 className="card-text">SKILLED  INSTRUCTORS</h5>
                <p className="card-text text-black">Experienced staff with motherly behaviour to make their students ready to avail shining  chances in the race of life.</p>

              </div>
            </div>
        </div>

        </div>
      </div>
    </div>
  );
}

export default Facilities;
