import React from 'react'
import DefualtFooter from './DefualtFooter'
import ContactDetail from './ContactDetail'

function Contact() {
  return (
    <div>
      {/* <ContactDetail/> */}
      <DefualtFooter/>
    </div>
  )
}

export default Contact