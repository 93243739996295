import React from 'react';
import { faculty } from '../apiData/Faculty';
import "../style/Faculty.css";
import DefaultFooter from './DefualtFooter';

function Faculty() {
  return (
    <div>
      <div className='container'>
      <div className='row justify-content-center align-items-center'>
        {faculty.map((x) => (  
          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 my-4' key={x.id}>
            <div className="card hover" style={{ width: '18rem' }}>
              {x.gender === "Male" ? (
                <img src="https://bootdey.com/img/Content/avatar/avatar6.png" className="img-fluid p-4" alt="Male faculty" />
              ) : (
                <img src="https://bootdey.com/img/Content/avatar/avatar8.png" className="img-fluid p-4" alt="Female faculty" />
              )}
              <div className="card-body" style={{ height: '150px', overflow: 'hidden' }}>
                <h5 className="card-text"><u>{x.name}</u></h5>
                <p className="card-text" style={{ fontSize: '14px' }}>{x.education}</p>
                <p className="card-text text-danger">{x.occupation}</p>
                
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <DefaultFooter/>
    
    </div>
  );
}

export default Faculty;
