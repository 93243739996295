export const faculty = [
    {
        id: 1,
        name: "Uzma Irfan",
        sn: "Mrs",
        gender: "Female",
        education: "M.A. M. ED.",
        occupation: "Principal",
        status: "Present"
    },
    {
        id: 2,
        name: "Ammara Liaqat",
        sn: "Miss",
        gender: "Female",
        education: "M.S. (Environmental Sciences), B. Ed.",
        occupation: "Vice Principal",
        status: "Present"
    },
    {
        id: 3,
        name: "Muhammad Usman",
        sn: "Mr",
        gender: "Male",
        education: "B.S. (Chemistry)",
        occupation: "",
        status: "Present"
    },
    {
        id: 4,
        name: "Muhammad Zubair",
        sn: "Mr",
        gender: "Male",
        education: "M.A. (Islamiat)",
        occupation: "Controller Examinations",
        status: "Present"
    },
    {
        id: 5,
        name: "Muhammad Iqbal",
        sn: "Mr",
        gender: "Male",
        education: "M.Sc. (Mathematics)",
        occupation: "Co-ordinator",
        status: "Present"
    },
    {
        id: 6,
        name: "Hifza Kausar",
        sn: "Miss",
        gender: "Female",
        education: "B.Sc.",
        occupation: "",
        status: "Present"
    },
    {
        id: 7,
        name: "Zainab Bashir",
        sn: "Miss",
        gender: "Female",
        education: "B.S. (Zoology)",
        occupation: "Co-ordinator",
        status: "Present"
    },
    {
        id: 8,
        name: "Aqsa Jamil",
        sn: "Miss",
        gender: "Female",
        education: "M.Sc. (Zoology)",
        occupation: "",
        status: "Present"
    },
    {
        id: 9,
        name: "Qura tul Ain",
        sn: "Miss",
        gender: "Female",
        education: "M.Com.",
        occupation: "",
        status: "Present"
    },
    {
        id: 10,
        name: "Jazba Rani",
        sn: "Miss",
        gender: "Female",
        education: "M.Sc. (IT)",
        occupation: "Admin Officer",
        status: "Present"
    },
    {
        id: 11,
        name: "Saira Arshad",
        sn: "Miss",
        gender: "Female",
        education: "B.Sc. (Chemistry, Zoology, Botany)",
        occupation: "",
        status: "Present"
    },
    {
        id: 12,
        name: "Nimra Mushtaq",
        sn: "Miss",
        gender: "Female",
        education: "B.Sc. (Maths, Computer)",
        occupation: "",
        status: "Present"
    },
    {
        id: 13,
        name: "Aiman Javed",
        sn: "Miss",
        gender: "Female",
        education: "B.Sc. (Maths, Computer)",
        occupation: "",
        status: "Ex"
    },
    {
        id: 14,
        name: "Hafsa Ilyas",
        sn: "Miss",
        gender: "Female",
        education: "M.Sc. (Chemistry)",
        occupation: "",
        status: "Ex"
    },
    {
        id: 15,
        name: "Sajida Jabeen",
        sn: "Miss",
        gender: "Female",
        education: "M.Sc. (IT)",
        occupation: "Accountant",
        status: "Ex"
    },
    {
        id: 16,
        name: "Faiza Yasin",
        sn: "Miss",
        gender: "Female",
        education: "M.Sc. (Zology)",
        occupation: "Co-ordinator",
        status: "Ex"
    }
    
]