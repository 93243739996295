export const career = [
    {
        id: 1,
        title: "Science Teacher",
        gender: "Female",
        education: "M.Sc. Zoology ",
        experience: "4 Years"
    },
    {
        id: 2,
        title: "Science Teacher",
        gender: "Female",
        education: "M.Sc. Mathematics",
        experience: "4 Years"
    },
    {
        id: 3,
        title: "General (Pre Classes)",
        gender: "Female",
        education: "Graduate",
        experience: "4 Years"
    },
    {
        id: 4,
        title: "Vice Principal",
        gender: "Female",
        education: "M.Sc.",
        experience: "10 Years in Administration"
    }
    

    
]