import React from 'react'
import DefaultFooter from './DefualtFooter'

function Glance() {
  return (
    <div>
      <div className="col-lg-8 col-md-7 m-auto my-5 py-5">
        <div className="team-single-text padding-50px-left sm-no-padding-left w-100  contact__wrapper shadow-lg p-5">
          <h4 className="font-size48 sm-font-size32 xs-font-size30 text-colorb"><b>CHS AT A Glance</b></h4>
          {/* <img src="/image/chs.jpg" alt /> */}

          <p className="no-margin-bottom text-colorb">
                The Comprehensive School
                System (CSS) was established in 1998.
                It is a highly specialized school that is
                infra – structurally geared and
                overwhelmingly committed to the
                cause of producing the best possible
                product of youths in the region.
            </p>
            <p className="no-margin-bottom text-colorb">
                The CSS is equipped with all
                modern amenities of education as
                Audio–Video & Computer
                Networking. The CSS contains
                discussion rooms, a library,
                laboratory, administrative and
                faculty offices.
            </p>
            
        
          
        </div>
      </div>
      <DefaultFooter/>
    </div>
  )
}

export default Glance